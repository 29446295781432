<template>
  <div class="scrollable">
    <div class="__loading" v-if="loadingTemplate">
      <div class="div" style="height: 80vh" v-loading="loadingTemplate"></div>
    </div>
    <div id="__messsages" v-if="!loadingTemplate">
      <div class="messages__navigation contains-message-buttons">
        <div class="main-buttons-container">
          <button
            type="button"
            class="__reporting__nav is-active create-template"
            @click="openMessageModal('template')">
            {{ $t("dashboard.send_message.message_templates.create_template") }}
          </button>
          <!-- Send Message Button -->
          <button
            type="button"
            class="is-active create-template"
            v-if="
              Array.isArray(messageTemplates) && messageTemplates.length > 0
            "
            @click="openMessageModal('sendMessage')">
            <img
              class="mr-1"
              src="../../../assets/send__light.svg"
              alt="send icon" />
            {{ $t("dashboard.send_message.message_templates.send_message") }}
          </button>
        </div>
        <!-- Send Message Button End -->
        <div class="tab-buttons-container">
          <button class="is-active-tab">
            {{ $t("dashboard.send_message.message_templates.template") }}
          </button>
          <button @click="scheduleMessage">
            {{
              $t("dashboard.send_message.scheduled_messages.scheduled_message")
            }}
          </button>
          <button @click="internationalMessage" class="international-button">
            {{
              $t("dashboard.send_message.international_messages.international")
            }}
          </button>
        </div>
      </div>

      <div class="__main_messages">
        <!-- MESSAGES EMPTY STATE -->
        <!-- MESSAGES EMPTY STATE -->
        <!-- MESSAGES -->
        <div class="__all__messages">
          <div id="__messages__card" class="mnotify-card">
            <div id="side-indicators-content" class="mnotify-card-header">
              <h3 class="mnotify-card-header-text py-0 my-0 notranslate">
                {{ $t("dashboard.send_message.message_templates.title") }}
              </h3>
            </div>
            <div class="mnotify-card-body">
              <div class="__nodata_messages">
                <div class="__nodata_messages table_wrapper">
                  <el-table
                    :header-cell-style="{
                      background: 'rgba(212, 216, 226, 0.1)',
                    }"
                    :data="displayData"
                    ref="singleTable"
                    :height="customHeight"
                    v-loading="tableLoading">
                    <template v-slot:empty>
                      <div>
                        <div class="no_message_message">
                          <h3 class="__nodata__header">
                            {{
                              $t(
                                "dashboard.send_message.message_templates.no_templates"
                              )
                            }}
                          </h3>
                          <button
                            class="__create_a_new_template"
                            @click="openMessageModal('template')">
                            {{
                              $t(
                                "dashboard.send_message.message_templates.create_template"
                              )
                            }}
                          </button>
                          <div class="__button_wrapper">
                            <button @click="openMessageModal('sendMessage')">
                              {{
                                $t(
                                  "dashboard.send_message.message_templates.send_message"
                                )
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </template>
                    <el-table-column
                      :label="$t('misc.table.message_title')"
                      prop="title"
                      color="red"
                      :width="customWidth">
                      <template slot-scope="scope">
                        <span class="notranslate">{{ scope.row.title }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('misc.table.content')"
                      :width="customWidth">
                      <template slot-scope="scope">
                        <span class="notranslate">
                          {{ scope.row.body.substr(0, 35)
                          }}{{ scope.row.body.length > 35 ? "..." : "" }}
                        </span>
                      </template>
                    </el-table-column>
                    <!-- Message Type -->
                    <el-table-column
                      :label="$t('misc.table.message_type')"
                      :width="customWidth">
                      <template slot-scope="scope">
                        <span
                          v-if="scope.row.type == 1"
                          style="color: #00b4d8"
                          class="message-type"
                          >SMS</span
                        >
                        <span
                          v-else-if="scope.row.type == 2"
                          style="color: #f7921c"
                          class="message-type"
                          >{{ $t("misc.voice") }}</span
                        >
                        <span
                          v-else-if="scope.row.type == 3"
                          style="color: #f56c6c"
                          class="message-type"
                          >{{ $t("misc.table.birthday") }}</span
                        >
                        <span
                          v-else-if="scope.row.type == 4"
                          style="color: #3762a5"
                          class="message-type">
                          <span v-if="scope.row.status == 1"
                            >{{ $t("misc.table.international") }}
                            <span style="color: #31cd54"
                              >({{ $t("misc.table.approved") }})</span
                            ></span
                          >
                          <span v-else-if="scope.row.status == 0"
                            >{{ $t("misc.table.international") }}
                            <span style="color: #f7921c"
                              >({{ $t("misc.table.pending") }})</span
                            ></span
                          >
                          <span v-else
                            >{{ $t("misc.table.international") }}
                            <span style="color: #f64a4a"
                              >({{ $t("misc.table.not_approved") }})</span
                            ></span
                          >
                        </span>
                      </template>
                    </el-table-column>
                    <!-- Message Type End -->
                    <el-table-column
                      :label="$t('misc.table.date_time')"
                      :width="customWidth">
                      <template slot-scope="scope">
                        {{
                          moment(scope.row.created_at).format(
                            "ddd D MMM, YYYY h:mm:ss a"
                          )
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('misc.table.actions')"
                      style="border: 1px solid red"
                      :width="customWidth">
                      <template slot-scope="scope">
                        <button
                          v-if="scope.row.status == 1"
                          @click="
                            opendModal({
                              message: scope.row,
                              key: scope.$index,
                              type: 'send',
                            })
                          "
                          class="tb_btn __send"
                          title="Send Message With Template">
                          <!-- <img width="20px" height="20px"
                                                        src="@/assets/Send.svg" alt=""> -->
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M19.4354 0.581983C18.9352 0.0685981 18.1949 -0.122663 17.5046 0.0786645L1.408 4.75952C0.679698 4.96186 0.163487 5.54269 0.0244302 6.28055C-0.117628 7.0315 0.378575 7.98479 1.02684 8.38342L6.0599 11.4768C6.57611 11.7939 7.24238 11.7144 7.66956 11.2835L13.4329 5.4843C13.723 5.18231 14.2032 5.18231 14.4934 5.4843C14.7835 5.77623 14.7835 6.24935 14.4934 6.55134L8.71999 12.3516C8.29181 12.7814 8.21178 13.4508 8.52691 13.9702L11.6022 19.0538C11.9623 19.6577 12.5826 20 13.2628 20C13.3429 20 13.4329 20 13.513 19.9899C14.2933 19.8893 14.9135 19.3558 15.1436 18.6008L19.9156 2.52479C20.1257 1.84028 19.9356 1.09537 19.4354 0.581983Z"
                              fill="#D4D8E2"
                              class="send-icon" />
                          </svg>
                        </button>
                        <!-- Edit Button -->
                        <button
                          v-if="scope.row.type != 4"
                          @click="
                            opendModal({
                              message: scope.row,
                              key: scope.$index,
                              type: 'edit',
                            })
                          "
                          class="tb_btn __send edit-contact"
                          title="Edit Template">
                          <svg
                            width="22"
                            height="20"
                            viewBox="0 0 22 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.7011 0.101062C17.1439 0.0119714 18.5666 0.506917 19.6387 1.47701C20.6206 2.5362 21.1216 3.94184 21.0414 5.37719V14.6228C21.1316 16.0581 20.6206 17.4638 19.6487 18.523C18.5767 19.493 17.1439 19.988 15.7011 19.8989H6.34292C4.8901 19.988 3.46734 19.493 2.39526 18.523C1.41335 17.4638 0.912379 16.0581 1.00255 14.6228V5.37719C0.912379 3.94184 1.41335 2.5362 2.39526 1.47701C3.46734 0.506917 4.8901 0.0119714 6.34292 0.101062H15.7011ZM10 14.801L16.7431 8.11919C17.3543 7.50546 17.3543 6.51556 16.7431 5.91173L15.4406 4.62487C14.8194 4.01114 13.8174 4.01114 13.1962 4.62487L12.5249 5.298C12.4247 5.39698 12.4247 5.56527 12.5249 5.66426C12.5249 5.66426 14.118 7.22829 14.1481 7.26788C14.2583 7.38667 14.3284 7.54505 14.3284 7.72323C14.3284 8.07959 14.0378 8.37656 13.6671 8.37656C13.4968 8.37656 13.3365 8.30727 13.2263 8.19838L11.553 6.55516C11.4729 6.47597 11.3326 6.47597 11.2524 6.55516L6.47317 11.2769C6.14253 11.6036 5.95216 12.0392 5.94214 12.5044L5.88202 14.8505C5.88202 14.9791 5.9221 15.0979 6.01227 15.187C6.10245 15.2761 6.22268 15.3256 6.35294 15.3256H8.70751C9.18844 15.3256 9.64933 15.1375 10 14.801Z"
                              fill="#D4D8E2" />
                          </svg>
                        </button>
                        <!-- Edit End -->
                        <button class="tb_btn _delete" title="Delete Template">
                          <!-- <img width="20px" height="20px"
                                                        @click="opendModal({ message: scope.row, key: scope.$index, type: 'delete' })"
                                                        src="@/assets/__delete.svg" alt=""> -->
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 19 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            @click="
                              opendModal({
                                message: scope.row,
                                key: scope.$index,
                                type: 'delete',
                              })
                            ">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.4185 3.30531C17.8087 3.30531 18.1335 3.62537 18.1335 4.03263V4.40918C18.1335 4.80653 17.8087 5.1365 17.4185 5.1365H0.796052C0.4049 5.1365 0.0800781 4.80653 0.0800781 4.40918V4.03263C0.0800781 3.62537 0.4049 3.30531 0.796052 3.30531H3.72042C4.31447 3.30531 4.83145 2.88813 4.96509 2.29954L5.11823 1.62374C5.35624 0.703186 6.13952 0.0917969 7.03595 0.0917969H11.1777C12.0643 0.0917969 12.8564 0.703186 13.0856 1.57518L13.2495 2.29855C13.3822 2.88813 13.8992 3.30531 14.4942 3.30531H17.4185ZM15.9329 17.0702C16.2382 14.259 16.7727 7.58027 16.7727 7.51289C16.7922 7.30877 16.7249 7.11554 16.5913 6.95997C16.4479 6.8143 16.2665 6.7281 16.0665 6.7281H2.15474C1.9538 6.7281 1.76262 6.8143 1.62996 6.95997C1.49535 7.11554 1.42902 7.30877 1.43877 7.51289C1.44056 7.52527 1.45974 7.76053 1.49181 8.15384C1.63426 9.90107 2.03102 14.7674 2.2874 17.0702C2.46884 18.7666 3.59547 19.8328 5.22738 19.8715C6.48668 19.9002 7.78402 19.9101 9.11062 19.9101C10.3602 19.9101 11.6292 19.9002 12.9275 19.8715C14.616 19.8427 15.7417 18.7953 15.9329 17.0702Z"
                              fill="#D4D8E2"
                              class="delete-icon" />
                          </svg>
                        </button>
                      </template>
                    </el-table-column>
                  </el-table>

                  <div class="" style="text-align: center; margin-top: 2em">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      @current-change="handleCurrentChange"
                      :page-size="pageSize"
                      :total="total">
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- MODAL CONTENT -->
      <!-- DELETE, UPDATE AND VIEW CONTACT MODAL  START -->

      <!-- DELETE,UPDATE AND VIEW CONTACT MODAL END -->
      <messageModal
        @saveTemplate="saveTemplate"
        @editTemplate="editTemplate"
        @deleteSuccess="handleDeleteSucccess"
        :payload="dataPayload"
        :from="from"
        ref="messageModal" />
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import messageModal from "./messageModal.vue";
  import store from "@/state/store.js";
  export default {
    name: "messages",
    components: { messageModal },
    data() {
      return {
        messages: "",
        success: "",
        errors: "",
        page: 1,
        pageSize: 20,
        total: 0,
        search: "",
        modalType: "",
        isDelete: false,
        rules: {},
        loadingTemplate: false,
        formData: {
          sederId: "",
          campaigne: "",
          content: "",
        },
        sendMessage: {},
        sendMessageRules: {},
        from: "",
        dataPayload: {
          currentModal: "",
          selectedKey: "",
          selectedMessage: {},
        },
        tableLoading: false,
      };
    },
    computed: {
      searching() {
        if (!this.search) {
          this.total = this.messageTemplates.length;
          return this.messageTemplates;
        }
        this.page = 1;
        return this.messageTemplates.filter(
          (data) =>
            data.title.toLowerCase().includes(this.search.toLowerCase()) ||
            data.voice_note_title
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      },
      displayData() {
        this.total = this.searching.length;
        return this.searching.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      },
      customWidth() {
        if (screen.width < 768) {
          return "150px";
        } else {
          return "min-content";
        }
      },
      customHeight() {
        if (screen.width < 768) {
          return "50vh";
        } else {
          return "60vh";
        }
      },
      messageTemplates() {
        return this.$store.getters["campaigns/templates"].filter(
          (template) => template.type !== 2
        );
      },
    },
    methods: {
      handleCurrentChange(val) {
        this.page = val;
      },
      scheduleMessage() {
        this.$router.push("/sms/scheduled_messages").catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      },
      internationalMessage() {
        this.$router.push("/sms/international-messages").catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      },
      saveTemplate(payload) {
        this.refreshedTemplates();
      },
      editTemplate(payload) {
        this.refreshedTemplates();
      },
      handleDeleteSucccess(id) {
        this.refreshedTemplates();
      },
      opendModal({ message, key, type }) {
        // get message template
        if (type === "delete") {
          this.dataPayload = {
            currentModal: "delete",
            selectedKey: key,
            selectedMessage: message,
          };
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "delete",
          });
        }
        if (type === "send") {
          this.dataPayload = {
            currentModal: "send",
            selectedKey: key,
            selectedMessage: message,
            goStraightToEdit: false,
          };
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "view",
          });
          store.dispatch("modal/addFormData", {
            currentPage: "",
            nextPage: "",
            prevPage: "",
            formData: {
              id: message.id,
              body: message.body,
              title: message.title,
              type: message.type,
              isEdit: false,
              pageTitle: "",
            },
          });
          // Show Schedule Button
          store.dispatch("uistate/setIsShowScheduleButtonConfirm", true);
        }
        if (type === "edit") {
          //  TODO: USE CANCEL INSTEAD OF BACK WHEN COMING FROM EDIT
          this.dataPayload = {
            currentModal: "send",
            selectedKey: key,
            selectedMessage: message,
            goStraightToEdit: true,
          };
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "view",
          });
          store.dispatch("modal/addFormData", {
            currentPage: "",
            nextPage: "",
            prevPage: "",
            formData: {
              id: this.dataPayload.selectedMessage.id,
              body: this.dataPayload.selectedMessage.body,
              title: this.dataPayload.selectedMessage.title,
              type: parseInt(this.dataPayload.selectedMessage.type),
              // sender_id: this.dataPayload.selectedMessage.sender_id.id.toString(),
              pageTitle: "",
            },
          });
        }

        return this.showModal();
      },
      getTemplates() {
        this.loadingTemplate = true;
        store.dispatch("campaigns/fetchTemplate").finally((res) => {
          this.loadingTemplate = false;
        });
      },
      showModal() {
        let element = this.$refs.messageModal.$el;
        $(element).modal({
          backdrop: "static",
          keyboard: false,
          show: true,
        });
      },
      openMessageModal(type) {
        if (type === "sendMessage") {
          this.from = "simpleMessage";
          this.$emit("SMSType", "simpleMessage");
          store.dispatch("uistate/addContactFormData", {
            title: this.$t(
              "dashboard.send_message.message_templates.send_message"
            ),
            type: "selectSenderId",
            fromMessage: true,
          });
          // Set Scheduled Type to false
          store.dispatch("uistate/setFromScheduled", false);
        } else if (type === "template") {
          this.$emit("SMSType", "template");
          this.from = "template";
          store.dispatch("uistate/addContactFormData", {
            title: this.$t(
              "dashboard.send_message.message_templates.create_template"
            ),
            type: "template",
            fromTemplate: false,
          });
          store.dispatch("modal/updateCurrentPage", "template");
        }
        return this.showModal();
      },
      refreshedTemplates() {
        store.dispatch("campaigns/fetchTemplate").finally(() => {
          this.tableLoading = false;
        });
      },
    },
    mounted() {
      this.getTemplates();
      // Analytics
      this.$mixpanel.track("SMS Messages Page");

      // for franco
      if (this.$store.getters["auth/currentUser"].country === "CI") {
        this.$mixpanelFranco.track("SMS Messages Page");
      }
    },
  };
</script>
<style scoped lang="scss">
  @import url("../../../styles/messages/___index.scss");

  .__create_a_new_template {
    outline: none;
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #484a4f;
    margin-top: 1em;
  }

  ._text_content {
    max-width: 300px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #575761;
  }

  @media screen and (max-width: 450px) {
    .__nodata__header {
      text-align: center;
    }
  }

  .is-active {
    background-color: #f7921c;
    color: white;
    border: none;
  }

  .__send:hover .send-icon {
    fill: #f7921c;
    transition: all 0.3s ease;
  }

  ._delete:hover .delete-icon {
    fill: red;
    transition: all 0.3s ease;
  }

  .__float__btn {
    // top: 2.5%;
    // position: absolute;
    // z-index: 0;
  }

  .contains-message-buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }

  .create-template {
    border-radius: 25px;
    border-color: #f7921c;
    width: auto;
    padding-inline: 1rem;
  }

  .edit-contact:hover svg path {
    cursor: pointer;
    fill: #00b4d8;
    transition: all 0.4s ease;
  }

  .is-active-tab {
    color: #f7921c;
    border: 1px solid #f7921c;
  }

  .scrollable {
    overflow-y: hidden;
    height: 88vh;
    overflow-x: hidden;
    /* padding-bottom: calc(20vh - 100px); */
  }

  @media screen and (max-width: 450px) {
    .scrollable {
      overflow-y: auto;
    }

    .contains-message-buttons {
      flex-direction: row-reverse !important;
      // grid-template-columns: 1fr 1fr;
      gap: 0;
    }

    .create-template {
      width: auto;
      padding-inline: 1rem;
      font-size: small;
    }
    .main-buttons-container {
      width: 100% !important;
    }

    .tab-buttons-container {
      width: 100% !important;
    }

    .tab-buttons-container button {
      width: 90% !important;
    }
  }

  .international-button {
    width: fit-content;
  }

  .is-new {
    background-color: #f56c6c;
    color: white;
    border: none;
    padding: 2px;
    border-radius: 5px;
    animation: new 1s ease;
    display: inline-block;
  }

  @keyframes new {
    30% {
      transform: scale(1.2);
    }

    40%,
    60% {
      transform: rotate(-20deg) scale(1.2);
    }

    50% {
      transform: rotate(20deg) scale(1.2);
    }

    70% {
      transform: rotate(0deg) scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
